/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Destination Expert</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <!-- create router link -->
                      <!-- <router-link to="CreateUser" class="btn btn-success
                      btn-small">Create</router-link>-->
                    </div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Destination Expert
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewDestinationList(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                      <div>
                        <router-link to="/createDestination" append>
                          <b-button class="createBtn" variant="success">Create</b-button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>

                          <th>Name</th>
                          <th>Mobile No</th>
                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in destinations" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>

                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.mobile }}</div>
                          </td>
                          <!-- v-repeat -->

                          <td>
                            <div class="d-flex">
                              <div class="ml-2 cursor-pointer">
                                <router-link
                                  class="btn btn-primary mr-2"
                                  :to="{ name: 'editDestination', params: { id: item._id } }"
                                  append
                                >
                                  <!-- <font-awesome-icon :icon="['fas', 'edit']" /> -->
                                  <b-icon-pencil></b-icon-pencil>
                                </router-link>
                              </div>
                              <!-- <div class="ml-2">
                                <deletemodal :mymodal="user"></deletemodal>
                              </div>-->
                              <div class="deletedModal">
                                <b-button
                                  variant="danger"
                                  v-b-modal.delete-modal
                                  @click="deleteDestinationById(item)"
                                >
                                  <b-icon-trash></b-icon-trash>
                                </b-button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <div>
                          <deletemodal
                            :mymodal="destinationData"
                            v-on:conformModal="deleteDestination"
                          ></deletemodal>
                        </div>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="destinations.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="destinations.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewDestinationList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import deletemodal from "@/components/deletemodal.vue";
import moment from "moment";

export default {
  components: {
    headerCustom,
    deletemodal
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      destinations: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      destinationData: {}
    };
  },
  created() {
    this.viewDestinationList(this.page);
  },
  methods: {
    viewDestinationList(pageNo) {
      this.destinations = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewDestinationList(this.formData, data => {
        if (data.status === 200) {
          this.destinations = data.data.result;
    
          this.totalCount = data.data.count;
        } else {
      
        }
      });
    },
    deleteDestinationById(destination) {
      this.destinationData = destination;
    },
    deleteDestination(destination) {
      service.deleteDestination(destination._id, result => {
        if (result.status == 200) {
          this.$toaster.success("Destination Deleted");
          this.viewDestinationList(1);
        } else {
          this.$toaster.errors("Something went wrong");
        }
      });
    }
  }
};
</script>

<style></style>
